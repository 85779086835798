import { StyledAnchor, StyledButton } from "./styles";
import { ButtonProps } from "../types";
import { AnchorProps } from "../types";

export const Button = ({
  color,
  fixedWidth,
  children,
  onClick,
}: ButtonProps) => (
  <StyledButton color={color} fixedWidth={fixedWidth} onClick={onClick}>
    {children}
  </StyledButton>
);

export const Anchor = ({
  color,
  fixedWidth,
  children,
  link,
}: AnchorProps) => (
  <StyledAnchor href={link} color={color} fixedWidth={fixedWidth} link={link}>
    {children}
  </StyledAnchor>
);
